<template>
    <div class="row container-query-insurance">
        <div class="col-lg-12">
            <div class="row group-container pt-4 group-main">
                <h5 class="title-group">แบบสอบถาม</h5>
                <div class="col-md-12">
                    <template v-for="(query, key) in data.query">
                        <button
                            :key="key"
                            @click="data.querySelect = key"
                            :class="[data.querySelect === key && 'active']"
                            class="btn btn-danger float-left mr-3"
                        >
                            {{ query.title }}
                        </button>
                    </template>
                </div>
                <div class="col-md-12"><hr /></div>
                <div class="col-md-12">
                    <h2 class="query-title text-center font-weight-bold mb-2">
                        {{ data.query[data.querySelect].title }}
                    </h2>
                    <div class="mb-5"><hr /></div>

                    <div v-if="data.query[data.querySelect].image" class="col-md-12 query-title-image mb-5 mt-5">
                        <img :src="data.query[data.querySelect].image" alt="" />
                    </div>

                    <template v-for="(query, key) in data.query[data.querySelect].form">
                        <div :key="key" class="row">
                            <div v-if="query.image" class="col-md-7 query-image">
                                <img :src="query.image" alt="" />
                            </div>
                            <div
                                v-if="query.type === 'checkbox'"
                                :class="[query.image ? 'col-md-5' : 'col-md-12 ml-4']"
                                class="query-checkbox"
                            >
                                <h4 v-if="query.text" class="checkbox-title" v-html="query.text"></h4>
                                <template v-for="(checkbox, keyCheckbox) in query.checkbox">
                                    <div :key="keyCheckbox" class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                :name="'checkbox-for-' + key"
                                                :id="'checkbox-for-' + key + '-' + keyCheckbox"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :value="checkbox"
                                            />
                                            <label
                                                :for="'checkbox-for-' + key + '-' + keyCheckbox"
                                                class="custom-control-label"
                                            >
                                                {{ checkbox }}
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                v-else-if="query.type === 'text'"
                                :class="[query.image ? 'col-md-5' : 'col-md-12 ml-4']"
                                class="query-checkbox"
                            >
                                <h4 v-if="query.text" class="checkbox-title">{{ query.text }}</h4>
                                <template v-for="(text, keyText) in query.checkbox">
                                    <div :key="keyText" class="form-group">
                                        <h4 v-html="text"></h4>
                                    </div>
                                </template>
                            </div>
                            <div class="col-md-12"><hr /></div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive } from '@vue/composition-api';

export default {
    name: 'cowrie',
    components: {},
    setup() {
        const form = reactive({
            checkbox: {
                'checkbox-for-0': '1',
            },
        });
        const data = reactive({
            form: {
                checkbox: {},
            },
            querySelect: 'health',
            query: {
                seriousDisease: {
                    title: 'แบบสอบถามโรคร้ายแรง',
                    image: null,
                    form: [
                        {
                            image: '/media/image/query/1.png',
                            type: 'checkbox',
                            text: 'อะไรคือเป็นสาเหตุหลักที่คร่าชีวิตคนไทยอันดับ 1',
                            checkbox: ['โรคมะเร็ง', 'โรคหัวใจ', 'โรคปอด', 'อุบัติเหตุ'],
                        },
                        {
                            image: '/media/image/query/S__6848556.jpg',
                            type: 'text',
                            text: 'สาเหตุหลักที่ทำให้เกิดมะเร็ง มีดังนี้ครับ',
                            checkbox: ['1. อาหาร ปิ้งๆย่างๆ เป็นสารก่อให้เกิดโรคมะเร็ง'],
                        },
                        {
                            image: '/media/image/query/S__6848558.jpg',
                            type: 'text',
                            text: false,
                            checkbox: ['2. อารม เคลียดจากการทำงาน เคลียดจากเรื่องครอบครัว'],
                        },
                        {
                            image: '/media/image/query/S__6848560.jpg',
                            type: 'text',
                            text: false,
                            checkbox: ['3. อากาศ มลพิษจากควันบุหรี่ ควันธูป และควันรถยนต์'],
                        },
                        {
                            image: '/media/image/query/S__6848561.jpg',
                            type: 'text',
                            text: false,
                            checkbox: [
                                '4. กรรมพันธุ์ ผู้ที่มีเครือญาติเป็นโรคมะเร็ง มีโอกาส 30% ที่จะเป็นโรคมะเร็งตามกรรมพันธุ์',
                            ],
                        },
                        {
                            image: '/media/image/query/3.jpeg',
                            type: 'checkbox',
                            text: 'มีญาติพี่น้องเสียชีวิตจากโรคมะเร็งไหมครับ',
                            checkbox: ['มี', 'ยังไม่มี', 'ไม่ทราบ'],
                        },
                        {
                            image: '/media/image/query/w644.jpeg',
                            type: 'checkbox',
                            text: 'จากสถิติพบว่าคนไทย 1 ใน 4 คน เป็นมะเร็ง 1 คน คุณลูกค้าทราบไหมครับ',
                            checkbox: ['ทราบดี', 'ไม่ทราบเลย'],
                        },
                        {
                            image: '/media/image/query/5.png',
                            type: 'checkbox',
                            text: 'จากสถิติของกรมการแพทย์ปี 2565 พบว่า ค่ารักษาพยาบาลด้วยโรคมะเร็งต้องใช้เงินเริ่มต้นที่ 500,000-10,000,000 บาท คุณลูกค้าคิดว่าแพงไหมครับ',
                            checkbox: ['แพงมาก', 'ไม่แพง'],
                        },
                        {
                            image: '/media/image/query/6.webp',
                            type: 'checkbox',
                            text: 'ถ้าวันนั้นมาถึง คุณคิดว่า วิธีการรับมือที่เหมาะสมที่สุด ต้องทำอย่างไร',
                            checkbox: [
                                'เก็บเงินที่ออมใว้มาใช้',
                                'ขายทรัพย์สินที่มีอยู่',
                                'ยืมญาติพี่น้อง',
                                'ขอรับบริจาค',
                            ],
                        },
                        {
                            image: '/media/image/query/7.webp',
                            type: 'checkbox',
                            text: 'ถ้าเลือกได้ระหว่างเราจ่ายค่ารักษาพยาบาลเอง กับบริษัทประกันจ่ายให้ คิดว่าเลือกอันใหนดีครับ',
                            checkbox: ['จ่ายเองทั้งหมด', 'บริษัทประกันจ่าย'],
                        },
                        {
                            image: '/media/image/query/8.jpeg',
                            type: 'checkbox',
                            text: 'ถ้ามีวิธีเตรียมเงิน 10 ล้านบาท เพียงใช้เงินหลักร้อย ลูกค้าว่าน่าสนใจไหมครับ',
                            checkbox: ['สนใจ', 'ไม่สนใจ'],
                        },
                    ],
                },
                health: {
                    title: 'แบบสอบถามสุขภาพ',
                    image: '/media/image/query/800AA502-381E-41D5-91C7-89BC7D920F57.jpg',
                    form: [
                        {
                            image: null,
                            type: 'checkbox',
                            text: 'ถ้าให้คะแนนสุขภาพเต็ม 10 คุณลูกค้าคิดว่าจะให้คะแนนสุขภาพตัวเองอยู่ที่เท่าไหร่ดี ครับ/ค่ะ',
                            checkbox: ['10 คะแนน', '8 คะแนน', '6 คะแนน', '5 คะแนน'],
                        },
                        {
                            image: null,
                            type: 'text',
                            text: 'ทำไมคะแนนถึงได้เท่านี้ครับ/ค่ะ แล้วคะแนนที่หายไปละ ครับ/ค่ะ',
                            checkbox: null,
                        },
                        {
                            image: null,
                            type: 'text',
                            text: 'เพราะอะไร...?',
                            checkbox: null,
                        },
                        {
                            image: null,
                            type: 'checkbox',
                            text: 'ในอนาคตข้างหน้าคิดว่าคะแนนสุขภาพของคุณจะเพิ่มขึ้น หรือลดลง ครับ/ค่ะ',
                            checkbox: ['เพิ่มขึ้น เพราะอะไรถึงเพิ่มขึ้น...?', 'ลดลง เพราะอะไรถึงลดลง...?'],
                        },
                        {
                            image: null,
                            type: 'checkbox',
                            text: 'แสดงว่าเราก็น่าจะมีโอกาสป่วยเพิ่มมากขึ้น ใช่หรือไม่ ครับ/ค่ะ',
                            checkbox: ['ใช่', 'ไม่ใช่'],
                        },
                        {
                            image: null,
                            type: 'checkbox',
                            text: 'ถ้าหากวันนั้นเกิดป่วยขึ้นมาจริงๆ คุณลูกค้าได้เตรียมวางแผลไว้อย่างไรบ้างครับ/ค่ะ (ไม่ต้องรอคำตอบ)',
                            checkbox: ['จ่ายเอง', 'บริษัท AIA จ่ายให้'],
                        },
                        {
                            image: null,
                            type: 'text',
                            text: 'ดีเลยครับ/ค่ะ วันนี้บริษัท AIA มีโครงการดีๆ ออกมาล่าสุด "โครงการโรคร้ายแรงเจอจ่ายจบ" ขออนุญาตเสนอข้อมูลเพิ่มเติมลูกค้าสัก 15 นาทีนะครับ/ค่ะ',
                            checkbox: null,
                        },
                    ],
                },
            },
        });

        return { data, form };
    },
};
</script>

<style lang="scss" scoped>
.query-title {
    color: #0073ff;
}
.query-image img {
    width: 95%;
    border-radius: 10px;
}
.query-title-image img {
    width: 95%;
    border-radius: 10px;
}
.checkbox-title {
    margin-top: 10px;
    margin-left: -10px;
}
.custom-checkbox {
    padding: 5px 10px;
    .custom-control-label {
        color: #34495e;
        margin-bottom: 0;
        font-size: 35px;
        font-weight: bold;
        padding-left: 40px;

        &::before {
            border: #33485e solid 5px;
        }
        &::before,
        &::after {
            top: -11px;
            width: 50px;
            height: 50px;
        }
    }

    .custom-control-input:checked ~ {
        .custom-control-label:before {
            background-color: #2c3e50;
        }
    }
}

@media (max-width: 992px) {
    .query-title {
        font-size: 30px;
    }
}
</style>
